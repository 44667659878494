import React, { useEffect, useState } from "react";
import Select from "react-select";
import Layout from "../layout/Layout";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faClipboardCheck,
  faDoorOpen,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";

import { Chart } from "react-google-charts";
import Cargando from "../components/Cargando";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";

import Moment from "react-moment";
import "moment/locale/es";
import "moment-timezone";
import silueta from "../silueta.png";

export default function Acceso() {
  const urlApi = "https://aforo.congresonayarit.gob.mx/api/";
  const [loading, setLoading] = useState(false);
  const [uuid, setUuid] = useState("");

  const [lectura, setLectura] = useState("");

  const [reloj, setReloj] = useState("");
  const [fecha, setFecha] = useState(Date().toLocaleString());

  const [enombre, setEnombre] = useState("");
  const [eap_paterno, setEap_paterno] = useState("");
  const [eap_materno, setEap_materno] = useState("");
  const [earea, setEarea] = useState("");
  const [efoto, setEfoto] = useState("");

  const [lastEmpleados, setLastEmpleados] = useState([]);

  const Cajita = (props) => (
    <div>
      <FontAwesomeIcon icon={faClipboardCheck} size="lg" /> {props.children}
    </div>
  );

  const intervaloR = () => {
    setReloj(new Date().toLocaleTimeString());
  };
  setInterval(intervaloR, 1000);

  useEffect(() => {
    // Actualizar ultimos empleados
    axios({
      method: "get",
      url: urlApi + "Empleado/Ultimos?api_token=" + localStorage.api_token,
      data: {},
    }).then((res) => {
      setLastEmpleados(res.data);
    });
  }, []);

  useEffect(() => {
    const fotovisible = setTimeout(() => {
      setEfoto(null);
    }, 4000);

    return () => {
      clearTimeout(fotovisible);
    };
  }, [efoto]);

  const lector = (e) => {
    if (e.key === "Enter") {
      let readcode = e.target.value;
      if (
        readcode.includes(
          "httpsÑ--congresonayarit.administracionpublica.mx-verificador-_id¿"
        )
      ) {
        readcode = readcode.replace(
          "httpsÑ--congresonayarit.administracionpublica.mx-verificador-_id¿",
          ""
        );
        readcode = readcode.replace(/'/g, "-");
        setUuid(readcode);
        axios({
          method: "get",
          url:
            "https://aforo.congresonayarit.gob.mx/user_data/api.php?uuid=" +
            readcode,
          data: {},
        }).then((res) => {
          setEnombre(res.data.datos.nombre);
          setEap_paterno(res.data.datos.primer_ap);
          setEap_materno(res.data.datos.segundo_ap);
          setEarea(res.data.puesto.direccion);
          setEfoto(
            "https://aforo.congresonayarit.gob.mx/user_data/imagen.php?t=doc_publico&id=Fotografia&w=225&h=300&id_usuario=" +
              readcode
          );

          // Registrar entrada/salida de empleados

          let data = {
            //Recoger la información para el registro
            uuid: readcode,
            nombre: res.data.datos.nombre,
            ap_paterno: res.data.datos.primer_ap,
            ap_materno: res.data.datos.segundo_ap,
            area: res.data.puesto.direccion,
            codigo: res.data.datos.cve_emp,
            api_token: localStorage.api_token,
          };
          let config = {};
          axios
            .post(
              "https://aforo.congresonayarit.gob.mx/api/registraAcceso",
              data,
              config
            )
            .then((resp) => {
              toast.success(<Cajita>{resp.data}</Cajita>, { autoClose: 3000 });

              axios({
                method: "get",
                url:
                  urlApi +
                  "Empleado/Ultimos?api_token=" +
                  localStorage.api_token,
                data: {},
              }).then((res) => {
                setLastEmpleados(res.data);
              });
            });
        });
      } else {
        toast.success("Código desconocido", { autoClose: 3000 });
      }
      setLectura("");
    }
  };

  return (
    <Layout>
      <Container>
        <Row className="pt-4">
          <Col>
            <h2>
              Acceso a Edificio
              <Moment locale="es" date={fecha} format="dddd LL" />
            </h2>
          </Col>
        </Row>

        <Row>
          <Col xs={9} md={9}>
            {" "}
            <p style={{ fontSize: "30px" }}>
              <FontAwesomeIcon icon={faBarcode} size="lg" /> /{" "}
              <FontAwesomeIcon icon={faQrcode} size="lg" />{" "}
              <input
                type="text"
                name="codigo"
                autoFocus
                autoComplete="off"
                style={{ width: "100px", height: "40px", fontSize: "30px" }}
                onKeyUp={lector}
                value={lectura}
                onChange={(e) => setLectura(e.target.value)}
              />
            </p>
          </Col>

          <Col
            xs={3}
            md={3}
            className="transparencia d-flex align-items-center"
          >
            <div className="reloj"> {reloj} </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={8} md={8} className="transparencia">
            <h3>Empleados</h3>
            <Form>
              UUID de la Credencial del Empleado
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="identificador">
                    <Form.Control
                      type="text"
                      placeholder="Clave de Credencial UUID"
                      value={uuid}
                      onChange={(e) => setUuid(e.target.value)}
                      autoComplete="off"
                    />
                    <Form.Text className="text-muted">
                      Escanee el código QR de la credencial del empleado
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              Nombre del Empleado
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Nombre"
                      value={enombre}
                      onChange={(e) => setEnombre(e.target.value)}
                    />
                    <Form.Text className="text-muted">Nombre</Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Apellido Paterno"
                      value={eap_paterno}
                      onChange={(e) => setEap_paterno(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                      Apellido Paterno
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Apellido Materno"
                      value={eap_materno}
                      onChange={(e) => setEap_materno(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                      Apellido Materno
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  Area:
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Area"
                      value={earea}
                      onChange={(e) => setEarea(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={4} md={4} className="transparencia">
            <center>
              <img style={{ width: "250px" }} src={efoto ? efoto : silueta} />
              <br />
            </center>
          </Col>
        </Row>
        <Row className="transparencia mt-3 pt-2">
          <Col>
            <center>
              <h4>Ultimos Registros</h4>
            </center>
            <Row>
              <Col>
                {lastEmpleados.map((empleado) => (
                  <React.Fragment key={empleado.fechahora}>
                    <strong>
                      <Moment fromNow locale="es" title={empleado.fechahora}>
                        {empleado.fechahora}
                      </Moment>
                    </strong>
                    <br />
                    {empleado.nombre} {empleado.ap_paterno}{" "}
                    {empleado.ap_materno}
                    >>>
                    <span className="text-muted">{empleado.area}</span>
                    <br />
                  </React.Fragment>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ToastContainer />
      <Cargando loading={loading} />
    </Layout>
  );
}
