import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/core";

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
`; 

export default function Cargando(props) {
  return (
    <React.Fragment>
      <div className={props.loading ? "parentDisable" : ""}></div>
      <MoonLoader
        loading={props.loading}
        size={80}
        color={"#512E5F"}
        css={override}
      />
    </React.Fragment>
  );
}
