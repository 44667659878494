import React from "react";
import Permission from "../auth/Permission";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBalanceScale,
  faBriefcase,
  faBuilding,
  faChartArea,
  faCog,
  faCogs,
  faDesktop,
  faFile,
  faGavel,
  faHandshake,
  faNewspaper,
  faPowerOff,
  faTachometerAlt,
  faUser,
  faUserFriends,
  faUsers,
  faUserShield,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCpanel,
  faDeskpro,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";

export default function Header() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
      <Permission />
      <Navbar.Brand>
        <img
          alt=""
          src="img/logo-nav.png"
          width="80"
          height=""
          className="d-inline-block align-top"
        />{" "}
        Aforo
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavLink to="/escritorio" className="nav-link">
            <FontAwesomeIcon icon={faTachometerAlt} /> Aforo
          </NavLink>

          <NavLink to="/AccesoE" className="nav-link">
            <FontAwesomeIcon icon={faBuilding} /> Empleados
          </NavLink>
          <NavLink to="/AccesoV" className="nav-link">
            <FontAwesomeIcon icon={faBuilding} /> Visitantes
          </NavLink>

          <NavLink to="/Acceso" className="nav-link">
            <FontAwesomeIcon icon={faBuilding} /> Mixto
          </NavLink>

          <NavLink to="/Empleados" className="nav-link">
            <FontAwesomeIcon icon={faBuilding} /> Empleados Hoy
          </NavLink>
          {/*
          <NavDropdown title="Reportes" id="basic-nav-dropdown">
            <NavLink to="/Reporterh" className="nav-link dropdown-item">
              <FontAwesomeIcon icon={faBuilding} /> Reporte RH
            </NavLink>

            <NavDropdown.Item href="/reporteseg" className="nav-link">
              Seguridad
            </NavDropdown.Item>
          </NavDropdown>
*/}

          <NavLink to="/Reporterh" className="nav-link">
            <FontAwesomeIcon icon={faBuilding} /> Reporte RH
          </NavLink>

          <NavLink to="/Reporteseg" className="nav-link">
            <FontAwesomeIcon icon={faBuilding} /> Reporte Seguridad
          </NavLink>
        </Nav>
        <span className="nombre_usuario">
          <FontAwesomeIcon icon={faUser} />
          &nbsp;{localStorage.nombre}
          &nbsp;{localStorage.ap_paterno}
          &nbsp;{localStorage.ap_materno}
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <NavLink className="nav-link" to="Logout" className="boton_salir">
          Salir <FontAwesomeIcon icon={faPowerOff} />
        </NavLink>
      </Navbar.Collapse>
    </Navbar>
  );
}
