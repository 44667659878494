import React from "react";

export default function Footer() {
  return (
    <div className="footer fixed-bottom">
      {" "}
      PODER LEGISLATIVO NAYARIT XXXIII Legislatura | Sistema Aforo 2021 &copy;
    </div>
  );
}
