import React from "react";
import { Redirect } from "react-router-dom";

export default function Permission() {
  return (
    <div>
      {localStorage.getItem("api_token") == null ? <Redirect to="/" /> : null}
    </div>
  );
}
 