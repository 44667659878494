import React from "react";
import Layout from "../layout/Layout";

export default function Forbidden() {
  return (
    <Layout>
      <h1>Acceso Denegado</h1>
    </Layout>
  );
} 
