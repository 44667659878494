import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { Container, Row, Col } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function Empleados() {
  const [empleadoshoy, setEmpleadoshoy] = useState([]);
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "fechahora",
      text: "Entrada",
    },
    {
      dataField: "nombre_completo",
      text: "Nombre",
    },

    {
      dataField: "area",
      text: "Area",
    },
    {
      dataField: "salida",
      text: "Salida",
    },
  ];

  useEffect(() => {
    axios({
      method: "get",
      url:
        "https://aforo.congresonayarit.gob.mx/api/empleadoshoy?api_token=" +
        localStorage.api_token,
      data: { api_token: localStorage.api_token },
    }).then((res) => {
      setEmpleadoshoy(res.data);
    });
  }, []);

  return (
    <Layout>
      <Container className="transparencia">
        <Row className="pt-4">
          <Col>
            <h2>Acceso de Empleados [Hoy]</h2>
          </Col>
        </Row>

        <Row>
          <Col>
            lassName="table-responsive">
            <ToolkitProvider
              keyField="id"
              data={empleadoshoy}
              columns={columns}
              search
            >
              {(props) => (
                <div>
                  <FontAwesomeIcon icon={faSearch} /> Búsqueda{" "}
                  <SearchBar
                    placeholder="Teclee su búsqueda"
                    {...props.searchProps}
                  />
                  <hr />
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Empleados;
