import React, { useState } from "react";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";
import { Form, Button, Row, Col, Card, Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cargando from "../components/Cargando";

export default function Login() {
  const apiURL = "https://aforo.congresonayarit.gob.mx/api";
  const history = useHistory();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const entrar = (e) => {
    setLoading(true);
    e.preventDefault();
    if (user === "" || password === "") {
      user === "" && toast.info("Debe escribir un nombre de usuario!");
      password === "" && toast.info("Debe escribir una contraseña!");
    } else {
      axios
        .post(apiURL + "/Usuario/Login", {
          usuario: user,
          password: password,
        })
        .then(function (response) {
          if (response.data.exito) {
            localStorage.setItem("api_token", response.data.exito.api_token);
            Object.keys(response.data.exito).forEach((element) => {
              localStorage.setItem(element, response.data.exito[element]);
            });
            history.push("/Escritorio");
          } else {
            toast.error("Usuario o contraseña incorrecta.");
          }

          setLoading(false);
        });
    }
    setLoading(false);
  };

  return (
    <div className="entrada">
      <Container>
        <ToastContainer />
        <Row className="justify-content-md-center">
          {localStorage.getItem("api_token") != null ? (
            <Redirect to="/escritorio" />
          ) : null}
          <Col xs={12} md={4}>
            <Card>
              <Card.Img variant="top" src="img/logo-login.png" />
              <Card.Body>
                <h5 className="card-title">Acceso al sistema</h5>

                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Usuario</Form.Label>
                    <Form.Control
                      type="text"
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                      required
                      placeholder="Escriba su nombre de usuario"
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      placeholder="Escriba su contraseña"
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" onClick={entrar}>
                    Entrar
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Cargando loading={loading} />
      </Container>
    </div>
  );
}
