import React from "react";
import Layout from "../layout/Layout";
import { Container, Row, Col } from "react-bootstrap";

function Visitantes() {
  return (
    <Layout>
      <Container>
        <Row className="pt-4">
          <Col>
            <h2>Visitantes Hoy</h2>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Visitantes;
