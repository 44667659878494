import React from "react";
import Layout from "../layout/Layout";

function Reporteseg() {
  return (
    <Layout>
      <div>Reporte Seguridad</div>
    </Layout>
  );
}

export default Reporteseg;
