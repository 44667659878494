import React, { useEffect, useState } from "react";
import Select from "react-select";
import Layout from "../layout/Layout";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faDoorOpen,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";

import { Chart } from "react-google-charts";
import Cargando from "../components/Cargando";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";

import Moment from "react-moment";
import "moment/locale/es";
import "moment-timezone";

export default function Acceso() {
  const urlApi = "https://aforo.congresonayarit.gob.mx/api/";
  const [loading, setLoading] = useState(false);
  const [uuid, setUuid] = useState("");
  const [idmex, setIdmex] = useState("");
  const [lectura, setLectura] = useState("");
  const [destinosOptions, setDestinosOptions] = useState();
  const [reloj, setReloj] = useState("");
  const [fecha, setFecha] = useState(Date().toLocaleString());

  const [vnombre, setVnombre] = useState("");
  const [vap_paterno, setVap_paterno] = useState("");
  const [vap_materno, setVap_materno] = useState("");
  const [destino, setDestino] = useState("");

  const [lastVisitantes, setLastVisitantes] = useState([]);

  const registrarAcceso = () => {
    if (idmex === "") {
      toast.warning("Capture el ID MEX del INE", { autoClose: 2000 });
    } else if (vnombre === "") {
      toast.warning("Capture el nombre del visitante", { autoClose: 2000 });
    } else if (destino === "") {
      toast.warning("Seleccione el destino del visitante", { autoClose: 2000 });
    } else {
      let data = {
        //Recoger la información para el registro
        //fechahora: fechahora,
        idmex: idmex,
        nombre: vnombre,
        ap_paterno: vap_paterno,
        ap_materno: vap_materno,
        destino: destino[0].label,
        api_token: localStorage.api_token,
      };
      let config = {};

      axios
        .post("https://aforo.congresonayarit.gob.mx/api/Visitante", data, config)
        .then((res) => {
          toast.success(res.data.text, { autoClose: 1000 });
        });

      // Actualizar Ultimos visitantes
      axios({
        method: "get",
        url: urlApi + "Visitante/Ultimos?api_token=" + localStorage.api_token,
        data: {},
      }).then((res) => {
        setLastVisitantes(res.data);
      });

      setIdmex("");
      setVnombre("");
      setVap_paterno("");
      setVap_materno("");
      setDestino([]);
    } //else
  };

  const intervaloR = () => {
    setReloj(new Date().toLocaleTimeString());
  };
  setInterval(intervaloR, 1000);

  useEffect(() => {
    //setLoading(true);
    axios({
      method: "get",
      url: urlApi + "DestinosOptions",
      data: {},
    }).then((res) => {
      setDestinosOptions(res.data);
    });

    axios({
      method: "get",
      url: urlApi + "Visitante/Ultimos?api_token=" + localStorage.api_token,
      data: {},
    }).then((res) => {
      setLastVisitantes(res.data);
    });
  }, []);

  const lector = (e) => {
    if (e.key === "Enter") {
      let readcode = e.target.value;
      if (
        readcode.includes(
          "httpsÑ--congresonayarit.administracionpublica.mx-verificador-_id¿"
        )
      ) {
        toast.info(
          "Esta es una credencial de Empleado, Use el módulo -Acceso Empleados- .",
          {
            autoClose: 3000,
          }
        );
      } else {
        setIdmex(readcode);

        axios
          .get(urlApi + "/Visitante/checaIdmex", {
            params: {
              idmex: readcode,
              api_token: localStorage.api_token,
            },
            data: {},
          })
          .then((res) => {
            if (res.data === "nuevo") {
              toast.info("NUEVO VISITANTE, POR FAVOR REGISTRE SUS DATOS.", {
                autoClose: 3000,
              });
            } else if (res.data === "salida") {
              setIdmex("");
              toast.warning("Se registró la salida del visitante", {
                autoClose: 2000,
              });
            } else {
              toast.info("Visitante conocido.", {
                autoClose: 2000,
              });

              setVnombre(res.data.nombre);
              setVap_paterno(res.data.ap_paterno);
              setVap_materno(res.data.ap_materno);
              setDestino([
                { label: res.data.destino, value: res.data.destino },
              ]);
            }
          });
      }

      setLectura("");
    }
  };

  return (
    <Layout>
      <Container>
        <Row className="pt-4">
          <Col>
            <h2>
              Acceso a Edificio{" "}
              <Moment locale="es" date={fecha} format="dddd LL" />
            </h2>
          </Col>
        </Row>

        <Row>
          <Col xs={9} md={9}>
            {" "}
            <p style={{ fontSize: "30px" }}>
              <FontAwesomeIcon icon={faBarcode} size="lg" /> /{" "}
              <FontAwesomeIcon icon={faQrcode} size="lg" />{" "}
              <input
                type="text"
                name="codigo"
                style={{ width: "100px", height: "40px", fontSize: "30px" }}
                onKeyPress={lector}
                value={lectura}
                onChange={(e) => setLectura(e.target.value)}
              />
            </p>
          </Col>

          <Col
            xs={3}
            md={3}
            className="transparencia d-flex align-items-center"
          >
            <div className="reloj"> {reloj} </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col
            className="transparencia"
            xs={{ span: 6, offset: 3 }}
            md={{ span: 6, offset: 3 }}
          >
            <h3>Visitantes</h3>
            <Form>
              ID MEX
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="identificador">
                    <Form.Control
                      type="text"
                      placeholder="Documento de Identidad"
                      value={idmex}
                      onChange={(e) => setIdmex(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                      ID MEX del INE (Escanee el reverso superior izquierdo)
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              Nombre del Visitante
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Nombre"
                      value={vnombre}
                      onChange={(e) => setVnombre(e.target.value)}
                    />
                    <Form.Text className="text-muted">Nombre</Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Apellido Paterno"
                      value={vap_paterno}
                      onChange={(e) => setVap_paterno(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                      Apellido Paterno
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Apellido Materno"
                      value={vap_materno}
                      onChange={(e) => setVap_materno(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                      Apellido Materno
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={8} md={8}>
                  Destino:
                  <Select
                    value={destino}
                    onChange={(e) =>
                      setDestino([{ label: e.value, value: e.value }])
                    }
                    options={destinosOptions}
                  />
                </Col>
                <Col xs={4} md={4} style={{ textAlign: "center" }}>
                  <br />
                  <Button onClick={registrarAcceso}>
                    <FontAwesomeIcon icon={faDoorOpen} /> Registrar Acceso
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className="transparencia mt-3 pt-2">
          <Col>
            <center>
              <h4>Ultimos Registros</h4>
            </center>
            <Row>
              <Col className="pb-3">
                <h5>Visitantes</h5>

                {lastVisitantes.map((visitante) => (
                  <React.Fragment>
                    <strong>
                      <Moment fromNow locale="es" title={visitante.fechahora}>
                        {visitante.fechahora}
                      </Moment>
                    </strong>{" "}
                    {visitante.nombre} {visitante.ap_paterno}{" "}
                    {visitante.ap_materno}
                    >>>
                    {visitante.destino}
                    <br />
                  </React.Fragment>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ToastContainer />
      <Cargando loading={loading} />
    </Layout>
  );
}
