import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row, Col } from "react-bootstrap";

export default function Layout(props) {
  return (
    <React.Fragment>
      <Header />
      <Container fluid>
        <Col xs={1} md={1}></Col>
        <Col>{props.children}</Col>

        <Col xs={1} md={1}></Col>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
