import React, { useState } from "react";
import Layout from "../layout/Layout";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import Moment from "react-moment";
import * as moment from "moment";
registerLocale("es", es);
function Reporterh() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    //  let finicio = moment(start).format("YYYY-MM-DD");
    // let ffin = moment(start).format("YYYY-MM-DD");
    console.log();
  };

  const generaExcel = () => {
    let x = moment(startDate).format("YYYY-MM-DD");
    let y = moment(endDate).format("YYYY-MM-DD");

    window.location =
      "https://aforo.congresonayarit.gob.mx/excel/reporteEmpleados.php?x=" +
      x +
      "&y=" +
      y;
  };
  return (
    <Layout>
      <Container className="transparencia">
        <Row className="pt-4">
          <Col>
            <h2> Reporte para Recursos Humanos </h2>
            Seleccione un rango de fechas.
          </Col>
        </Row>
        <Row>
          <Col>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              locale="es"
            />
          </Col>
          <Col>
            <Button className="mx-auto" onClick={generaExcel}>
              Generar Reporte <FontAwesomeIcon icon={faFileExcel} />
            </Button>
          </Col>
          <Col></Col>
          <br />
          <br />
          <br />
          <br />
        </Row>
      </Container>
    </Layout>
  );
}

export default Reporterh;
