import React, { useEffect, useState } from "react";
import Select from "react-select";
import Layout from "../layout/Layout";
import {
  Container,
  Card,
  CardGroup,
  CardDeck,
  Row,
  Col,
  Table,
  Breadcrumb,
  Button,
  Modal,
  Form,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faPaperPlane,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
import { faTelegram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import axios from "axios";
import moment from "moment";
import "moment/locale/es";

import { Chart } from "react-google-charts";
import Cargando from "../components/Cargando";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Pie } from "react-chartjs-2";

import GaugeChart from "react-gauge-chart";

export default function Escritorio() {
  const urlApi = "https://aforo.congresonayarit.gob.mx/api/";

  const [showModalNotificador, setShowModalNotificador] = useState(false);
  const [loading, setLoading] = useState(false);
  const [empleadosIn, setEmpleadosIn] = useState(0);
  const [visitantesIn, setVisitantesIn] = useState(0);

  const intervaloR = () => {
    axios({
      method: "get",
      url: urlApi + "Empleado/In?api_token=" + localStorage.api_token,
      data: {},
    }).then((res) => {
      setEmpleadosIn(res.data);
    });

    axios({
      method: "get",
      url: urlApi + "Visitante/In?api_token=" + localStorage.api_token,
      data: {},
    }).then((res) => {
      setVisitantesIn(res.data);
    });
  };
  setInterval(intervaloR, 60000);

  useEffect(() => {
    axios({
      method: "get",
      url: urlApi + "Empleado/In?api_token=" + localStorage.api_token,
      data: {},
    }).then((res) => {
      setEmpleadosIn(res.data);
    });

    axios({
      method: "get",
      url: urlApi + "Visitante/In?api_token=" + localStorage.api_token,
      data: {},
    }).then((res) => {
      setVisitantesIn(res.data);
    });
  }, []);

  return (
    <Layout>
      <Container fluid="md">
        <Row className="pt-2">
          <Col>
            <br />
            <h2>H Congreso del Estado de Nayarit XXXIII Legislatura</h2>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col className="transparencia" xs={12} md={12}>
            <h3>
              {visitantesIn} Visitantes y {empleadosIn} Empleados{" "}
            </h3>
            <h4>Aforo Máximo 400 personas</h4>

            <GaugeChart
              id="gauge-chart1"
              nrOfLevels={20}
              percent={(empleadosIn + visitantesIn) / 400}
              needleColor="#444444"
              needleBaseColor="#444444"
              textColor="#000000"
            />
          </Col>
        </Row>
      </Container>

      <ToastContainer />
      <Cargando loading={loading} />
    </Layout>
  );
}
