import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import Forbidden from "./auth/Forbidden";
import Escritorio from "./pages/Escritorio";
import Acceso from "./pages/Acceso";
import Reportes from "./pages/Reportes";
import Visitantes from "./pages/Visitantes";
import Empleados from "./pages/Empleados";

import AccesoE from "./pages/AccesoE";
import AccesoV from "./pages/AccesoV";

import Reporterh from "./pages/Reporterh";
import Reporteseg from "./pages/Reporteseg";

import "tachyons";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />

        <Route exact path="/Login" component={Login} />
        <Route exact path="/Logout" component={Logout} />
        <Route exact path="/Forbidden" component={Forbidden} />
        <Route exact path="/Escritorio" component={Escritorio} />
        <Route exact path="/Acceso" component={Acceso} />

        <Route exact path="/AccesoE" component={AccesoE} />
        <Route exact path="/AccesoV" component={AccesoV} />

        <Route exact path="/Visitantes" component={Visitantes} />
        <Route exact path="/Empleados" component={Empleados} />

        <Route exact path="/Reporterh" component={Reporterh} />
        <Route exact path="/Reporteseg" component={Reporteseg} />

        <Route exact path="/Reportes" component={Reportes} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
